import React, { ReactElement, Suspense } from "react"
import DemoHTML from "./DemoHTML"

interface Props {}

export default function Animation({}: Props): ReactElement {
  return (
    // <Suspense fallback={<></>}>
      <DemoHTML />
    // </Suspense>
  )
}
