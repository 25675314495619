import React, { ReactElement } from "react"

interface Props {}

export default function OldPage({}: Props): ReactElement {
  return (
    <main className="min-s flex flex-col justify-center items-center">
      <section className="holding text-center">
        <div className="container px-5">
          <div className="max-w-2xl mx-auto space-y-5">
            <h1>Hello World</h1>
            <div className="prose md:prose-lg">
              <p>
                Thank you for making the effort to view my website, but i'm afraid
                to say that I haven't quite finished it yet. So feel free to check
                out my Github and LinkedIn page for more information. Otherwise come back soon!
              </p>
            </div>
            <div className="inline-flex">
              <div className="social-icons mt-2 grid grid-cols-2 gap-6">
                <a
                  href="https://github.com/ryanturnerwebdev"
                  className="w-7"
                  title="GitHub"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 120.775 117.793"
                  >
                    <path
                      d="M60.388,0A60.394,60.394,0,0,0,41.3,117.69c3.018.559,4.126-1.31,4.126-2.905,0-1.439-.056-6.2-.082-11.243C28.541,107.194,25,96.416,25,96.416c-2.747-6.98-6.7-8.836-6.7-8.836-5.479-3.748.413-3.671.413-3.671,6.063.426,9.257,6.224,9.257,6.224,5.386,9.231,14.127,6.563,17.573,5.019.542-3.9,2.106-6.567,3.834-8.075-13.413-1.526-27.514-6.705-27.514-29.844a23.37,23.37,0,0,1,6.223-16.209c-.627-1.522-2.694-7.663.585-15.982,0,0,5.071-1.622,16.61,6.191a57.262,57.262,0,0,1,30.243,0c11.526-7.813,16.59-6.191,16.59-6.191,3.287,8.318,1.22,14.46.593,15.982a23.324,23.324,0,0,1,6.215,16.209c0,23.194-14.127,28.3-27.574,29.8,2.166,1.874,4.1,5.549,4.1,11.183,0,8.08-.069,14.583-.069,16.572,0,1.607,1.087,3.49,4.147,2.9A60.4,60.4,0,0,0,60.388,0Z"
                      fill="white"
                      fillRule="evenodd"
                    ></path>
                    <path
                      d="M22.872,86.7c-.133.3-.605.391-1.035.184s-.684-.606-.542-.907.6-.395,1.04-.189.688.61.537.911Zm-.743-.551"
                      fill="white"
                    ></path>
                    <path
                      d="M25.318,89.432c-.288.267-.852.143-1.233-.279a.923.923,0,0,1-.177-1.255c.3-.267.843-.142,1.238.279s.473.984.172,1.255Zm-.576-.618"
                      fill="white"
                    ></path>
                    <path
                      d="M27.7,92.909c-.37.258-.976.017-1.35-.52s-.37-1.182.009-1.439.971-.025,1.35.507.369,1.19-.009,1.453Zm0,0"
                      fill="white"
                    ></path>
                    <path
                      d="M30.961,96.27c-.331.365-1.036.267-1.552-.231a1.162,1.162,0,0,1-.344-1.543c.336-.365,1.045-.263,1.564.231s.684,1.182.331,1.543Zm0,0"
                      fill="white"
                    ></path>
                    <path
                      d="M35.461,98.221c-.146.473-.825.688-1.509.486s-1.13-.761-.992-1.238.824-.7,1.513-.485,1.13.756.988,1.237Zm0,0"
                      fill="white"
                    ></path>
                    <path
                      d="M40.4,98.582c.017.5-.563.911-1.281.92s-1.307-.387-1.314-.877.567-.911,1.289-.924,1.307.387,1.307.881Zm0,0"
                      fill="white"
                    ></path>
                    <path
                      d="M45,97.8c.086.485-.413.984-1.126,1.117s-1.35-.172-1.439-.653c-.087-.5.421-1,1.121-1.126s1.353.168,1.444.662Zm0,0"
                      fill="white"
                    ></path>
                  </svg>{" "}
                </a>
                <a
                  href="https://www.linkedin.com/in/ryan-turner-82883ba7/"
                  className="w-7"
                  title="LinkedIn"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 430.117 430.117"
                  >
                    <path fill="white" d="M430.117,261.543V420.56h-92.188V272.193c0-37.271-13.334-62.707-46.703-62.707c-25.473,0-40.632,17.142-47.301,33.724c-2.432,5.928-3.058,14.179-3.058,22.477V420.56h-92.219c0,0,1.242-251.285,0-277.32h92.21v39.309c-0.187,0.294-0.43,0.611-0.606,0.896h0.606v-0.896c12.251-18.869,34.13-45.824,83.102-45.824C384.633,136.724,430.117,176.361,430.117,261.543z M52.183,9.558C20.635,9.558,0,30.251,0,57.463c0,26.619,20.038,47.94,50.959,47.94h0.616c32.159,0,52.159-21.317,52.159-47.94C103.128,30.251,83.734,9.558,52.183,9.558zM5.477,420.56h92.184v-277.32H5.477V420.56z"></path>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}
