import React, { ReactElement } from "react"
import "./DemoStyles.css"
import ScrollStage from "./code/index"
import OldPage from "../OldPage"

interface Props {}

export default function DemoHTML({}: Props): ReactElement {
  const contentRef = React.createRef<HTMLDivElement>()
  const [isLoaded, setIsloaded] = React.useState(false)
  React.useEffect(() => {
    if (contentRef && contentRef.current && !isLoaded) {
      new ScrollStage(contentRef.current)
      setIsloaded(true)
    }
  }, [contentRef])

  return (
    <>
      <div className="content" ref={contentRef}>
        <div className="scroll__stage">
          <div className="scroll__content">
            <section className="section">
              <OldPage />
            </section>
          </div>
        </div>
        <div className="layout__line"></div>
      </div>
    </>
  )
}
