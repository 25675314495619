import React from "react"
import { PageProps } from "gatsby"
import { Helmet } from "react-helmet"
import Layout from "~/components/Layout"
// import SEO from "~/components/Seo"
// import OldPage from "~/components/OldPage"
import Animation from "~/components/animation/Animation"


const IndexPage: React.FC<PageProps> = () => (
  <Layout>
    <Helmet
      title="Home — Ryan Turner | Web Developer"
      description="Ryan Turner is a web developer based in London, UK. He specializes in building modern, responsive websites and applications."
    />
    <Animation />

  </Layout>
)

export default IndexPage
